/**
 * The Copy Block will be used for secondary or tertiary informational content on Creative Pages
 *
 * @module views/components/CreativeCopyBlock
 * @memberof -Common
 */
import './CreativeCopyBlock.scss';

import React from 'react';

import classNames from 'classnames';
import PropTypes from 'prop-types';

import Text from '@ulta/core/components/Text/Text';
import UltaAsset from '@ulta/core/components/UltaAsset/UltaAsset';

import Link from '../Link/Link';

/**
* Represents a CreativeCopyBlock component
*
* @method
* @param {CreativeCopyBlockProps} props - React properties passed from composition
* @returns CreativeCopyBlock
*/
export const CreativeCopyBlock = function( props ){
  return (
    <div className='CreativeCopyBlock'>
      <div className={ classNames( 'CreativeCopyBlock__gridWrapper', {
        'CreativeCopyBlock__content--emptyImage': !props.image,
        'CreativeCopyBlock__content--image': props.image
      } ) }
      >
        <div className={ classNames( 'CreativeCopyBlock__backgroundColor', {
          ...props.backgroundColor ? { ['CreativeCopyBlock__backgroundColor--' + props.backgroundColor]: true } : {}
        } ) }
        ></div>
        <div className='CreativeCopyBlock__leftcolumn'>
          {
            props.image &&
            <div className='CreativeCopyBlock__image' >
              <UltaAsset
                { ...( props.image ) }
              />
            </div>
          }
        </div>
        <div className={ classNames( 'CreativeCopyBlock__rightcolumn', {
          'CreativeCopyBlock__rightcolumn--empty': !props.image
        } )
        }
        >
          {
            props.copy &&
            <div className={ classNames( 'CreativeCopyBlock__copy', {
              'CreativeCopyBlock__copy--empty': !props.image
            } )
            }
            >
              <Text
                htmlTag='p'
                textStyle='subtitle-1'
              >
                { props.copy }
              </Text>
            </div>
          }
          { props.linkAction?.url &&
            <div className={ classNames( 'CreativeCopyBlock__action', {
              'CreativeCopyBlock__action--empty': !props.image
            } )
            }
            >
              <Link
                linkAction={ props.linkAction }
                linkIcon='ArrowForward'
                linkStyle='title-6'
              />
            </div>
          }
        </div>
      </div>
    </div>
  );
};

/**
* Property type definitions
* @typedef CreativeCopyBlockProps
* @type {object}
* @property {object} image - Set the image to display
* @property {string} altText - Sets the altText of image
* @property {string} copy - Set the copy text
* @property {string} backgroundColor - Set the background color
* @property {object} linkAction - Set the linkAction
*/

export const propTypes =  {
  /** Set the image to display */
  image: PropTypes.object,
  /** Sets the altText for image */
  altText: PropTypes.string,
  /** Set the headline text */
  copy: PropTypes.string,
  /** Set the background Color */
  backgroundColor: PropTypes.string,
  /** Set the linKAction */
  linkAction: PropTypes.object
};

CreativeCopyBlock.propTypes = propTypes;

export default CreativeCopyBlock;